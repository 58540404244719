.landing-page {
	display: flex;
	background-color: $black;
	color: $white;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
	text-align: center;
	.container {
		width: 270px;
		max-width: 100%;
	}
	header {
		margin-bottom: 40px;
		.logoW {
			position: relative;
			&:after {
				content: "";
				display: block;
				position: absolute;
				width: 2530px;
				height: 1500px;
				border-radius: 50%;
				background-color: $lgrey;
				bottom: 0px;
				margin-left: 50%;
				transform: translateX(-50%);
				z-index: 0;
				transition: all 0.3s ease-in-out;
			}

			.logo {
				position: relative;
				z-index: 1;
			}
		}
		h1 {
			font-size: 0;
			width: 100%;
			position: relative;
			margin: 0;
			span {
				height: 0;
				display: block;
				padding-bottom: 27%;
				background: url("../assets/brand/logo-inv.svg") no-repeat center/contain;
			}
		}
	}
	footer {
		margin-top: 40px;
	}

	.cta-buttons {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
		.button {
			margin: 0;
			display: block;
			width: 200px;
		}
	}
}
