.page-dashboard {
	height: 100%;
	display: flex;
	flex-direction: column;
	.container {
		padding: 0 15px;
		flex: 1 1;
		main {
			height: 100%;
			position: relative;
			display: flex;
			justify-content: center;
			& > div {
				padding: 20px 15px;
				width: 500px;
				max-width: 100%;
				max-height: 100%;
				overflow-y: scroll;
				.selected-attendees {
					display: flex;
					gap: 10px;
					margin-bottom: 15px;
					.selected-attendee {
						position: relative;
						.remove {
							position: absolute;
							top: -5px;
							right: -5px;
							z-index: 1;
							border-radius: 50%;
							width: 20px;
							height: 20px;
							font-size: 15px;
							display: flex;
							align-items: center;
							justify-content: center;
							border: 2px solid;
							background-color: $white;
							// @media (prefers-color-scheme: dark) {
							// 	background-color: $black;
							// }
							svg {
								width: 15px;
								height: 15px;
								padding: 0;
								margin: 0;
								font-size: 15px;
							}
						}
					}
				}
				.quick-adds {
					display: grid;
					grid-auto-flow: column;
					text-align: center;
					grid-auto-columns: 1fr;
					gap: 10px;
					.quick-add {
						cursor: pointer;
						.icon {
							font-size: 2rem;
						}
						.title {
							font-weight: bold;
							margin-top: 5px;
							white-space: nowrap;
						}
						.amount {
							font-size: 0.9em;
							opacity: 0.75;
						}
					}
				}
			}
		}
	}
}
