.result {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 15px;
	margin-bottom: 20px;
	.icon {
		height: 60px;
		width: 60px;
		border: 3px solid;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.25rem;
	}
	.text {
		flex: 1;
	}
	&[data-type="user"] {
		.username {
			font-weight: bold;
			font-size: 1.1rem;
		}
		.name {
			line-height: 1.3;
			opacity: 0.8;
		}
		.followedby {
			font-size: 0.9rem;
			line-height: 1.2;
			opacity: 0.8;
		}
	}
	&[data-type="place"] {
		.name {
			font-weight: bold;
			font-size: 1.1rem;
		}
		.address {
			line-height: 1.3;
			opacity: 0.8;
			font-size: 0.9rem;
		}
	}
	&[data-type="drink"] {
		.name {
			font-weight: bold;
			font-size: 1.1rem;
		}
		.type {
			line-height: 1.3;
			opacity: 0.8;
		}
	}
}
