.like-button {
	position: relative;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 12px;
	transition: all 0.3s ease;

	&::after {
		z-index: -2;
		position: absolute;
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		background: #f6f6f8;
		border-radius: 12px;
		transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
	}

	svg {
		transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
		path {
			transition: all 0.2s ease;
		}
	}

	&.active {
		color: $primary;
	}

	&:hover {
		&::after {
			border-radius: 10px;
			background: #feeef3;
			transform: scale(0.88);
		}
	}

	&:active {
		svg {
			transform: scale(0.6);
		}
	}
	i {
		position: absolute;
		font-size: 12px;
		font-weight: 600;
		padding: 6px 10px;
		color: white;
		background: $primary;

		.wine-mode & {
			background: $secondary;
		}
		border-radius: 24px;
		line-height: 12px;
		z-index: -9;
		transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
		font-family: -apple-system, sans-serif;
		font-style: normal;
	}

	.count-motion {
		transform: translatey(-160%);
	}

	span {
		opacity: 1;
		z-index: -99;
		position: absolute;
		width: 90%;
		height: 90%;
		border-radius: 100%;
		background: radial-gradient(
			50% 50% at 50% 50%,
			rgba(255, 255, 255, 0) 0%,
			#feeff3 100%
		);
	}

	.bubble-motion {
		animation: bubbleMotion 0.8s cubic-bezier(0.25, 1, 0.5, 1) forwards;

		@keyframes bubbleMotion {
			to {
				transform: scale(2.6);
				opacity: 0;
			}
		}
	}
}
