.profile-nav {
	position: relative;
	padding: 0 15px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	gap: 15px;
	font-size: 1.25rem;
	background-color: $white;
	box-shadow: 0 1px 5px 0 rgba($black, 0.05);
	.title {
		font-weight: bold;
		font-size: 1.125rem;
	}
	.cancel,
	.menu {
		position: absolute;
		top: 0;
		bottom: 0;
		width: 50px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.cancel {
		left: 0;
	}
	.menu {
		right: 0;
	}
}
.profile-page {
	.container {
		padding: 0 15px;
		header {
			height: 184px;
			text-align: center;
			padding: 15px 0;
			.avatarIcon {
				position: relative;
				margin: 0 auto 5px;
				&:before,
				&:after {
					content: "";
					position: absolute;
					display: block;
					height: 100px;
					width: 136px;
					top: 0;
					background: url("../assets/flash.svg") no-repeat;
				}
				&:before {
					right: 120%;
					transform: scaleX(-1);
				}
				&:after {
					left: 120%;
				}
			}
			.name {
				font-weight: bold;
				font-family: "Kaushan Script", cursive;
				font-size: 1.5em;
				margin: 15px 0;
			}

			.actions {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 10px;
				margin: 30px 0 10px;
				.button {
					margin-top: 0;
				}
				button {
					width: 100%;
					transition: 0.1s ease-in-out;
					&:hover {
						background-color: $primary;
						border-color: $primary;
						.wine-mode & {
							background-color: $secondary;
							border-color: $secondary;
						}
					}
				}
			}
		}
	}
}
