// Document
:root {
	font-family: "Sen", Avenir, Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;

	background-color: $black;

	font-synthesis: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
	background-color: $lgrey;
	@media screen and (min-width: 600px) {
		background: url("../../src/assets/background.jpg") center/cover no-repeat
			$lgrey fixed;
	}
}

html,
body {
	height: 100%;
	width: 100vw;
	margin: 0;
	overflow-x: hidden;
}

#root {
	margin: 0 auto;
	padding: 0;
	height: 100%;
	width: 100vw;
	max-width: 600px;
	min-width: 300px;
	overflow-x: hidden;
	position: relative;
	box-shadow: 0 0 5px 0 rgba($black, 0.25);
	background-color: $lgrey;
	color: $black;
	text-align: left;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	.no-scroll &,
	.smooth & {
		overflow: hidden !important;
	}
}

* {
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */

	&:before,
	&:after {
		box-sizing: border-box;
	}

	&:focus {
		outline: none !important;
	}
}

// Links
a {
	color: inherit;
	cursor: pointer;
	text-decoration: none;

	&:hover,
	&:focus {
		color: inherit;
		text-decoration: none;
	}
}

a,
button,
label,
input {
	-webkit-tap-highlight-color: transparent;
}

// Media
img,
svg,
canvas,
audio,
video,
iframe {
	vertical-align: middle;
}

figure {
	margin: 0;
}

// Inputs
input,
button,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	user-select: text;
	font-size: 1rem;
}

// Buttons
button,
[type="button"],
[type="reset"],
[type="submit"] {
	&:not(:disabled) {
		cursor: pointer;
	}
}

// Text
p,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote {
	margin: 0 0 15px;
	padding: 0;
}

em,
address {
	font-style: normal;
}

p {
	margin-bottom: 15px;
}

fieldset {
	margin: 0;
	padding: 0;
	border: none;
}

fieldset + fieldset {
	margin-top: 15px;
}

#root {
	height: 100%;
	width: 100%;
	overflow-x: hidden;
	overflow-y: scroll;
}

button {
	border: 1px solid transparent;
	font-size: 1em;
	font-family: inherit;
	background-color: transparent;
	cursor: pointer;
	color: $black;
}

button:focus,
button:focus-visible {
	outline: 4px auto -webkit-focus-ring-color;
}

.visually-hidden {
	display: none;
}

.button {
	margin-top: 15px;
	border-radius: 15px;
	border: 1px solid transparent;
	padding: 0.6em 1.2em;
	font-size: 1em;
	font-weight: 500;
	font-family: inherit;
	cursor: pointer;
	transition: border-color 0.25s;
	background-color: $black;
	color: $white;
	&:hover {
		color: $white;
	}
	&:disabled {
		opacity: 0.5;
	}
	&.primary {
		background-color: $primary;
		.wine-mode & {
			background-color: $secondary;
		}
		color: $white;
	}
}
.button:focus,
.button:focus-visible {
	outline: 4px auto -webkit-focus-ring-color;
}

.site-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	.main-content {
		flex: 1;
		overflow-y: scroll;
		overflow-x: hidden;
	}
}

.row {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}
