.beersW {
	position: relative;
	width: 100%;
	padding: 20px 20px 50px;
	margin-bottom: 20px;
	min-height: 246px;
	color: $black;

	.title {
		position: relative;
		text-align: center;
		margin: 20px 0 10px;
		font-size: 0.9em;
		line-height: 1.2;

		span {
			font-size: 1.2rem;
			display: block;
			font-weight: bold;
		}
	}

	.beers {
		z-index: 1;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		min-height: 115px;

		.text {
			margin-top: 15px;
			font-weight: bold;
			font-size: 1.25em;
		}
		.beer {
			align-self: flex-end;
			position: relative;
			width: 70px;

			.amount {
				position: absolute;
				display: block;
				text-align: center;
				top: 100%;
				left: 50%;
				font-weight: bold;
				transform: translateX(-50%);
				margin-top: 5px;
				white-space: nowrap;
				font-size: 0.9em;
				.comment {
					margin-top: 5px;
					font-weight: normal;
					font-style: italic;
					line-height: 1;
				}
			}
			.glass {
				position: relative;
				background: url("../../assets/beer/glass.svg") bottom center/auto
					no-repeat;
				// @media (prefers-color-scheme: dark) {
				// 	background-image: url("../../assets/beer/glass-inv.svg");
				// }
				margin-left: 15%;
				padding-bottom: 130%;
				&::after {
					content: "";
					position: absolute;
					width: 100%;
					height: 5%;
					background-color: $black;
					bottom: -2%;
					z-index: -1;
					left: 5%;
					opacity: 0.25;
					border-radius: 50%;
				}
			}
			.liquidW {
				display: block;
				position: absolute;
				bottom: 3%;
				left: 18%;
				width: 56%;
				height: 82%;
				.liquid {
					width: 100%;
					height: 0%;
					position: absolute;
					bottom: 0;
					left: 0;
					opacity: 0.9;
					transition: height 1s ease;
					background-color: $orange;
					border-top: 0px solid $white;
					// @media (prefers-color-scheme: dark) {
					// 	// background-color: $secondary;
					// 	border-top: 0px solid $white;
					// }
				}
			}
			&.empty,
			&.half {
				margin-top: 5px;
				.glass {
					background-image: url("../../assets/beer/glass.svg");
					// @media (prefers-color-scheme: dark) {
					// 	background-image: url("../../assets/beer/glass-inv.svg");
					// }
					padding-bottom: 123%;
				}
			}
			&.half {
				.liquidW {
					height: 87%;
					.liquid {
						border-top-width: 3px;
					}
				}
			}
			&.full {
				&::after {
					position: absolute;
					content: "";
					top: -8px;
					width: 100%;
					height: 50px;
					background: url("../../assets/beer/foam.svg") top center/auto
						no-repeat;
					// @media (prefers-color-scheme: dark) {
					// 	background-image: url("../../assets/beer/foam-inv.svg");
					// }
				}
			}
			&.keg {
				width: 75px;
				.glass {
					background-image: url("../../assets/beer/keg-grad.svg");
					// @media (prefers-color-scheme: dark) {
					// 	background-image: url("../../assets/beer/keg-grad-inv.svg");
					// }
					margin-left: 0;
					padding-bottom: 116%;
					&::after {
						height: 6%;
						left: 10%;
						bottom: -2%;
					}
				}
				.liquidW {
					height: 0;
					.liquid {
						border: none;
					}
				}
			}
		}
	}
}
