.pagination {
	margin-top: 20px;
	margin-bottom: 20px;
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		justify-content: center;
		li {
			border: 1px solid black;
			border-left: none;
			&.prev {
				border-left: 1px solid;
			}
			span {
				height: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
				min-width: 30px;
				padding: 0 10px;
				cursor: pointer;
				&:hover {
					background-color: rgba($color: $black, $alpha: 0.05);
				}
			}
			&.active {
				span {
					background-color: $black;
					color: white;
				}
			}
			&.disable {
				span {
					opacity: 0.5;
					cursor: default;
					&:hover {
						background-color: inherit;
					}
				}
			}
		}
	}
}
