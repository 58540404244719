.location-nav {
	padding: 0 15px;
	height: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	gap: 15px;
	font-size: 1.25rem;
	background-color: $white;
	position: relative;
	z-index: 1;
	box-shadow: 0 1px 5px 0 rgba($black, 0.1);
	.title {
		font-weight: bold;
		font-size: 1.125rem;
	}
	.cancel,
	.menu {
		width: 24px;
		cursor: pointer;
	}
}

.profile-location {
	.wifi {
		span {
			user-select: text;
			cursor: pointer;
		}
	}
	.map-container {
		margin: 0 -15px -30px;
	}

	.content {
		position: relative;
		border-top-left-radius: 30px;
		border-top-right-radius: 30px;
		background-color: white;
		padding: 40px 20px;
		text-align: center;
		.title {
			font-family: "Kaushan Script", cursive;
			margin-bottom: 2rem;
		}
		.type {
			position: relative;
			text-transform: uppercase;
			margin-bottom: 1.5rem;
			opacity: 0.75;
			&:after {
				content: "";
				position: absolute;
				display: block;
				background-color: $black;
				left: 0;
				right: 0;
				top: 50%;
				height: 1px;
				z-index: -2;
				opacity: 0.5;
			}
			.typeW {
				display: inline-block;
				position: relative;

				&:before {
					content: "";
					position: absolute;
					display: inline-block;
					background-color: $white;
					left: -10px;
					right: -10px;
					height: 100%;
					z-index: -1;
				}
			}
		}
		.address {
			margin-bottom: 1.5rem;
		}
		.wifi {
			margin-bottom: 2rem;
		}
	}
}
