.winesW {
	position: relative;
	width: 100%;
	padding: 20px 20px 50px;
	margin-bottom: 20px;
	min-height: 246px;
	color: $black;

	.title {
		position: relative;
		text-align: center;
		margin: 20px 0 10px;
		font-size: 0.9em;
		line-height: 1.2;

		span {
			font-size: 1.2rem;
			display: block;
			font-weight: bold;
		}
	}

	.wines {
		z-index: 1;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		min-height: 115px;

		.text {
			margin-top: 15px;
			font-weight: bold;
			font-size: 1.25em;
		}
		.wine {
			align-self: flex-end;
			position: relative;
			width: 70px;

			.amount {
				position: absolute;
				display: block;
				text-align: center;
				top: 100%;
				left: 50%;
				font-weight: bold;
				transform: translateX(-50%);
				margin-top: 5px;
				white-space: nowrap;
				font-size: 0.9em;
				.comment {
					margin-top: 5px;
					font-weight: normal;
					font-style: italic;
					line-height: 1;
				}
			}

			.container {
				position: relative;
				background: center bottom/contain no-repeat;
				padding-bottom: 120%;
				&::after {
					content: "";
					position: absolute;
					width: 100%;
					height: 5%;
					background-color: $black;
					bottom: -2%;
					z-index: -1;
					left: 10%;
					opacity: 0.15;
					border-radius: 50%;
				}
			}

			.liquidW {
				display: none;
				position: absolute;
				bottom: 54%;
				left: 21%;
				width: 59%;
				height: 30%;
				.liquid {
					border-bottom-right-radius: 50px;
					border-bottom-left-radius: 50px;
					width: 100%;
					height: 0%;
					position: absolute;
					bottom: 0;
					left: 0;
					opacity: 0.9;
					transition: all 1s ease;
					background-color: $red;
				}
			}

			&.full,
			&.half,
			&.empty {
				.liquidW {
					display: block;
				}
				.container {
					background-image: url("../../assets/wine/wine-glass.svg");
				}
			}

			&.full {
				.liquidW {
					.liquid {
						height: 100%;
					}
				}
			}

			&.bottle {
				.container {
					background-image: url("../../assets/wine/wine-bottle-full.svg");
					margin-left: 0;
					padding-bottom: 160%;
				}
			}

			&.keg {
				.container {
					background-image: url("../../assets/beer/keg-grad.svg");
					margin-left: 0;
					padding-bottom: 120%;
					&::after {
						height: 6%;
						left: 10%;
						bottom: -2%;
					}
				}
			}
		}
	}
}
