.avatarIcon {
	border-radius: 50%;
	// overflow: hidden;
	max-width: 100%;
	border: 3px solid;
	position: relative;
	border-color: $primary;
	.avatar {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		border-radius: 50%;
		border: 3px solid $white;
		overflow: hidden;
		.image {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			object-fit: cover;
		}

		.loader {
			background-color: $black;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			z-index: 1;
			transition: 0.3s opacity ease-out;
			&.finished {
				opacity: 0;
			}
		}
		&.no-image {
			background-color: $black;
		}
	}
}

.wine-mode {
	.avatarIcon {
		border-color: $secondary;
	}
}
