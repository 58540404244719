$black: #222;
// $primary: #fc8682;
// $primary: #1d59c5;
$primary: #fba83f;
// $primary: #3b90f1;
// $primary: #aaa;
// $primary: #eb4664;
// $primary: rgb(8, 130, 160);
// $secondary: rgb(8, 130, 160);
$secondary: #eb4664;
$white: #fff;
$lgrey: #f7f5f2;

$orange: #fba83f;
// $red: #fc8682;
$red: #eb4664;
$blue: #1d59c5;
