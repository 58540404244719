.bottom-menu {
	&.active {
		bottom: 0;
	}
	position: absolute;
	transition: bottom 0.5s ease;
	bottom: -100%;
	left: 0;
	right: 0;
	background-color: black;
	color: white;
	z-index: 200000;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	ul {
		list-style: none;
		padding: 10px 0 100px;
		margin: 0;
		li {
			& + li {
				border-top: 1px solid;
			}
			a {
				padding: 15px;
				display: block;
			}
		}
	}
}

.bottom-menu-overlay {
	&.active {
		height: 100%;
		opacity: 1;
	}
	opacity: 0;
	transition: opacity 0.25s ease;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 0;
	z-index: 199999;
	background-color: rgba(black, 0.1);
}
