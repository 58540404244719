.profile-drink {
	nav {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
	.illustration {
		width: 150px;
		height: 150px;
		margin: 0 auto;
		background: url("../assets/lager.png") top center/contain;
	}
	.hero {
		height: 250px;
		background: url("../assets/drink-header-lager.jpg") center/cover no-repeat
			#000;
		margin-bottom: -30px;
	}
	.drink-nav {
		position: absolute;
		z-index: 100;
		top: 0;
		left: 0;
		right: 0;
		margin: 0;
		padding: 0 15px;
		height: 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-align: center;
		gap: 15px;
		font-size: 1.25rem;
		background-color: transparent;
		color: white;
		.title {
			font-weight: bold;
			font-size: 1.125rem;
		}
		.cancel,
		.menu {
			width: 24px;
			cursor: pointer;
		}
	}
	.content {
		position: relative;
		border-top-left-radius: 30px;
		border-top-right-radius: 30px;
		padding: 40px 20px;
		background-color: $white;
		text-align: center;
		.title {
			font-family: "Kaushan Script", cursive;
			margin-bottom: 2rem;
		}
		.origin {
			position: relative;
			text-transform: uppercase;
			margin-bottom: 1.5rem;
			opacity: 0.75;
			&:after {
				content: "";
				position: absolute;
				display: block;
				background-color: $black;
				left: 0;
				right: 0;
				top: 50%;
				height: 1px;
				z-index: -2;
				opacity: 0.5;
			}
			.originW {
				display: inline-block;
				position: relative;

				&:before {
					content: "";
					position: absolute;
					display: inline-block;
					background-color: $white;
					left: -10px;
					right: -10px;
					height: 100%;
					z-index: -1;
				}
			}
		}
		.pills {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
		}
		.rating,
		.favourite {
			border-radius: 22px;
			line-height: 1;
			font-weight: bold;
			min-width: 70px;
			padding: 0 10px;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 5px;
			.icon {
				font-size: 1em;
			}
		}
		.rating {
			background-color: rgba($primary, 0.5);

			.wine-mode & {
				background-color: rgba($secondary, 0.5);
			}
			// @media (prefers-color-scheme: dark) {
			// 	background-color: rgba($secondary, 0.5);
			// }
		}
		.favourite {
			background-color: $black;
			color: $white;
			// @media (prefers-color-scheme: dark) {
			// 	background-color: $white;
			// 	color: $black;
			// }
		}
		.description {
			text-align: center;
			.label {
				font-weight: bold;
			}
		}
		footer {
			text-align: center;
			padding: 20px 0;
			margin-top: 1rem;
			.button {
				&.active {
					color: $primary;
					// @media (prefers-color-scheme: dark) {
					// 	color: $secondary;
					// }
				}
			}
		}
	}

	.map-container {
		margin-top: 20px;
		background-color: rgba($black, 0.1);
	}
}
