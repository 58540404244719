.dateNav {
	height: 44px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 -15px 20px;
	padding: 10px 59px;
	font-weight: bold;
	background-color: $black;
	color: $white;
	box-shadow: inset 0 1px 3px 0 rgba($white, 0.25);
	// @media (prefers-color-scheme: dark) {
	// 	background-color: $secondary;
	// 	color: $black;
	// 	box-shadow: inset 0 1px 3px 0 rgba($black, 0.25);
	// }
	& > div {
		cursor: pointer;
	}
	.date {
		text-transform: uppercase;
	}
	.prev,
	.next {
		position: absolute;
		top: 0;
		bottom: 0;
		width: 44px;
		display: flex;
		justify-content: center;
		align-items: center;
		&::before {
			content: "";
			position: absolute;
			display: block;
			width: 1px;
			background-color: $white;
			opacity: 0.5;
			top: 10px;
			bottom: 10px;
		}
	}
	.prev {
		left: 0;
		&::before {
			right: 0;
		}
	}
	.next {
		right: 0;
		&::before {
			left: 0;
		}
	}
	.hide {
		opacity: 0;
		cursor: default;
	}
}
