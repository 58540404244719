.accounts-nav {
	position: absolute;
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	margin: 0;
	padding: 0 15px;
	height: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 15px;
	font-size: 1.5rem;
	background-color: $white;
	box-shadow: 0 1px 5px 0 rgba($black, 0.05);
	// @media (prefers-color-scheme: dark) {
	// 	background-color: $black;
	// 	box-shadow: 0 1px 5px 0 rgba($white, 0.05);
	// }
	.title {
		text-align: left;
		flex: 1;
		font-weight: bold;
		font-size: 1.125rem;
	}
	.cancel {
		margin-right: 20px;
		cursor: pointer;
	}
	.confirm {
		cursor: pointer;
		color: $primary;

		.wine-mode & {
			color: $secondary;
		}
		// @media (prefers-color-scheme: dark) {
		// 	color: $secondary;
		// }
	}
}

.accounts-page {
	.container {
		padding: 0 15px;

		main {
			position: absolute;
			top: 50px;
			left: 0;
			right: 0;
			bottom: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			& > div {
				padding: 15px;
				width: 500px;
				max-width: 100%;
				max-height: 100%;
				overflow-y: scroll;
			}
		}
	}
}
