.Total {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 -20px 20px;
	padding: 10px 25px;
	font-weight: bold;
	background-color: $black;
	color: $white;
	box-shadow: inset 0 1px 3px 0 rgba($white, 0.25);
	.title {
		text-transform: uppercase;
	}
}
