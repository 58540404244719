// ToggleSwitch.scss

.toggle-switch {
	text-align: center;

	.title {
		font-weight: bold;
		margin-bottom: 0.5rem;
		display: none;
	}
	.container {
		display: flex;
		justify-content: center;
		align-items: center;

		.icon {
			height: 30px;
			width: 30px;
			background: center/contain no-repeat;

			&.--beer {
				background-image: url("../../assets/toggle-beer.svg");
				margin-right: 3px;
			}
			&.--wine {
				background-image: url("../../assets/toggle-wine.svg");
			}
		}
	}

	input[type="checkbox"] {
		height: 0;
		width: 0;
		visibility: hidden;
		display: none;

		&:checked + label {
			background: $red;
		}

		&:checked + label:after {
			left: calc(100% - 2px);
			transform: translateX(-100%);
		}
	}

	label {
		margin-top: 2px;
		cursor: pointer;
		text-indent: -9999px;
		width: 45px;
		height: 25px;
		background: $orange;
		display: block;
		border-radius: 100px;
		position: relative;

		&:after {
			content: "";
			position: absolute;
			top: 2px;
			left: 2px;
			width: 20px;
			height: 21px;
			background: $white;
			border-radius: 50%;
			transition: 0.3s;
		}
		&:active::after {
			width: 22px;
		}
	}
}
