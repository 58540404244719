.map-container {
	height: 200px;
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $white;
	.pin {
		position: absolute;
		bottom: 50%;
		left: 50%;
		z-index: 1;
		font-size: 3rem;
		transform: translateX(-50%);
		color: $primary;

		.wine-mode & {
			color: $secondary;
		}

		&::before {
			display: block;
			position: absolute;
			content: "";
			width: 20px;
			border-radius: 50%;
			height: 5px;
			background-color: $black;
			bottom: -1px;
			left: 8px;
			opacity: 0.5;
			z-index: -1;
		}
		// color: $blue;
		// @media (prefers-color-scheme: dark) {
		// 	color: $secondary;
		// }
	}
	img {
		// max-width: 100%;
		opacity: 0.75;
		width: 100%;
		height: 200px;
		object-fit: cover;
	}
}
